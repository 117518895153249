import React from "react";
import { Row, Col } from "reactstrap";

class Settings extends React.Component {
  render() {
    return (
      <div className="content">
        <Row>
          <Col xs="12">Configuraciones</Col>
        </Row>
      </div>
    );
  }
}

export default Settings;
