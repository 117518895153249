import React from "react";
import { Row, Col } from "reactstrap";

import API from "../api";
import AnalyticsFilter from "../components/Analytics/AnalyticsFilter";

import ChartList from "../components/Analytics/ChartList.jsx";

class Historical extends React.Component {
  state = {
    charts: [],
    loading: false,
    endpoint: "general",
  };

  clearCharts = () => {
    this.setState({ charts: [] });
  };

  onQuery = (criteria) => {
    const { deviceID, from, to, dataGroupIDs } = criteria;
    const promises = [];

    // Loading charts
    this.setState({ loading: true });

    dataGroupIDs.forEach((dataGroupID) => {
      const query = { deviceID, from, to, dataGroupID };
      promises.push(API.getDataGroupChartData(query, this.state.endpoint));
    });

    Promise.all(promises)
      .then((response) => {
        this.setState({ charts: response, loading: false });
      })
      .catch((error) => {
        console.error("Historical -> error", error);
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <AnalyticsFilter
                onClear={this.clearCharts}
                onSearch={this.onQuery}
                onUpdateEndpoint={(endpoint) => this.setState({ endpoint })}
              />
            </Col>
            <Col xs="12">
              <ChartList
                loading={this.state.loading}
                charts={this.state.charts}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Historical;
