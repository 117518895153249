import React, { useEffect, useState } from "react";
import { Card, Row, Col, CardBody,CardTitle, CardHeader } from "reactstrap";

export default function PumpRTValuesMBIP(props) {
    const { data } = props;
    return (
        <Row className="mt-5 justify-center remove-margin-top">
            {
                data.map((mbip, i) => {
                    return (
                        <Col key={'mbip' + i} xs="12" md="3">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4"><span className="text-success h2">{mbip.variableName}</span></CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <span className="text-white h1">{mbip.value.toFixed(2)}</span>
                                    <span className="text-success h4">{mbip.unit}</span>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                })
            }
        </Row>
    );
}
