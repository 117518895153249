import React, { useState, useEffect } from "react";

import PumpOn from "assets/img/pump-circle-green.png";
import PumpRed from "assets/img/pump-circle-red.png";

export default function PumpMonitor(props) {
  const { pumpState, loading } = props;
  const [isTurnOn, setTurnOn] = useState(false);
  const [available, setAvailable] = useState(false);

  useEffect(() => {
    setAvailable(!!pumpState);

    switch (pumpState.substring(0, 3)) {
      case "001":
      case "011":
      case "111":
        setTurnOn(true);
        break;
      case "000":
      case "100":
      case "110":
        setTurnOn(false);
        break;
    }
  }, [pumpState]);

  return (
    <div className="monitor">
      {/* Loading state */}
      {(loading || !available) && (
        <div className="bomb unavailable">
          <img width="85" src={PumpRed} alt="PumpRed" />
          <p>Conectando...</p>
        </div>
      )}

      {/* On state */}
      {available && isTurnOn && !loading && (
        <div className="bomb on">
          <p>Bomba Encendida</p>
          <img width="85" src={PumpOn} alt="PumpOn" />
        </div>
      )}

      {/* Off state */}
      {available && !isTurnOn && !loading && (
        <div className="bomb off">
          <img width="85" src={PumpRed} alt="PumpOff" />
          <p>Bomba Apagada</p>
        </div>
      )}
    </div>
  );
}
