import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";

import API from "../../api";
import EndpointSelector from "./EndpointSelector";

const baseQuery = {
  deviceID: "",
  from: "",
  to: "",
  dataGroupIDs: [],
  metricsToDraw: [],
};

export default function AnalyticsFilter(props) {
  const { onSearch, onClear, allMetrics, onUpdateEndpoint } = props;
  const [query, setQuery] = useState(baseQuery);
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState();
  const [dataGroups, setDataGroups] = useState();
  const [metricsToDraw, setMetricsToDraw] = useState([]);

  const onUpdateQuery = (e) => {
    const { value, name, checked } = e.target;
    switch (name) {
      case "dataGroupIDs":
        // Data group id need to handle checked/unchecked state
        if (!query.dataGroupIDs) {
          setQuery({ ...baseQuery, ...query, [name]: [value] });
        } else {
          let dataGroupsIds = [];
          const metrics = dataGroups
            .filter((dg) => dg.dataGroupId == value)[0]
            .metrics.map((m) => m.simbol);
          if (!checked) {
            dataGroupsIds = query.dataGroupIDs.filter((id) => id !== value);
            setMetricsToDraw(
              metricsToDraw.filter((mts) => !metrics.includes(mts))
            );
          } else {
            dataGroupsIds = [...query.dataGroupIDs, value];
            setMetricsToDraw((mts) => [...mts, ...metrics]);
          }
          setQuery({
            ...baseQuery,
            ...query,
            [name]: dataGroupsIds,
            metricsToDraw: metricsToDraw,
          });
        }
        break;

      case "from":
        const fromDate = new Date(value);

        fromDate.setDate(fromDate.getDate()); // Hack to add a day

        const toDate = fromDate.toISOString();

        setQuery({
          ...baseQuery,
          ...query,
          [name]: value,
          to: toDate.substring(0, ((toDate.indexOf("T") | 0) + 6) | 0),
        });
        break;
      case "metricsToDraw":
        if (checked) {
          setMetricsToDraw((metrics) => [...metrics, value]);
        } else {
          setMetricsToDraw(metricsToDraw.filter((metric) => metric !== value));
        }
        setQuery({
          ...baseQuery,
          ...query,
          metricsToDraw: [...query.metricsToDraw, value],
        });
        break;
      default:
        setQuery({ ...baseQuery, ...query, [name]: value });
    }
  };

  // Get all needed info
  useEffect(() => {
    setLoading(true);

    async function fetchDevices() {
      const data = await API.getDevices();
      const firstSelected = data[0];
      setQuery({ ...baseQuery, deviceID: firstSelected.deviceId.toString() });
      setDevices(data);
    }

    async function fetchDataGroups() {
      const data = await API.getDataGroups();
      setDataGroups(data);
    }

    fetchDevices();
    fetchDataGroups();
  }, []);

  // Check loading
  useEffect(() => {
    const fullLoading = !!devices && !!dataGroups;

    setLoading(!fullLoading);
  }, [devices, dataGroups]);

  const buildOptions = () => {
    return (
      <>
        {dataGroups &&
          dataGroups.map((dg) => (
            <Col xs="6" sm="3" key={dg.dataGroupId}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    value={dg.dataGroupId}
                    name="dataGroupIDs"
                    onChange={onUpdateQuery}
                    checked={query.dataGroupIDs.includes(
                      dg.dataGroupId.toString()
                    )}
                  />
                  <span className="form-check-sign">
                    <span className="check" />
                  </span>
                  {dg.name}
                </Label>
                {allMetrics &&
                  query.dataGroupIDs.indexOf(dg.dataGroupId.toString()) >
                    -1 && (
                    <Col sm={{ size: 11, offset: 1 }}>
                      {dg.metrics.map((mt) => (
                        <>
                          <Label check>
                            <Input
                              type="checkbox"
                              // value={dg.dataGroupId + "," + mt.simbol}
                              value={mt.simbol}
                              name="metricsToDraw"
                              onChange={onUpdateQuery}
                              checked={metricsToDraw.includes(mt.simbol)}
                            />
                            <span className="form-check-sign">
                              <span className="check" />
                            </span>
                            {mt.simbol}
                          </Label>
                          <br />
                        </>
                      ))}
                    </Col>
                  )}
              </FormGroup>
            </Col>
          ))}
      </>
    );
  };

  return (
    <Card body className="analytics-filters">
      <CardHeader>
        <h6>Filtros</h6>
        <hr />
        <EndpointSelector onSelectEndpoint={onUpdateEndpoint} />
      </CardHeader>
      <CardBody>
        <Form
          onSubmit={(e) => {
            // Hugh!
            e.preventDefault();

            onSearch(query);
          }}
        >
          <Row className="justify-content-center">
            {loading ? (
              <Spinner color="light" />
            ) : (
              <>
                <Col xs="12" sm="3">
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label className={"filter-label"}>Dispositivo:</Label>
                        <Input
                          type="select"
                          name="deviceID"
                          value={query.deviceID}
                          onChange={onUpdateQuery}
                        >
                          {devices &&
                            devices.map((d) => (
                              <option key={d.deviceId} value={d.deviceId}>
                                {d.name}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col xs="12">
                      <FormGroup>
                        <Label className={"filter-label"}>Desde:</Label>
                        <Input
                          type="datetime-local"
                          name="from"
                          value={query.from}
                          max={query.to}
                          onChange={onUpdateQuery}
                        />
                      </FormGroup>
                    </Col>

                    <Col xs="12">
                      <FormGroup>
                        <Label className={"filter-label"}>Hasta:</Label>
                        <Input
                          type="datetime-local"
                          name="to"
                          value={query.to}
                          min={query.from}
                          onChange={onUpdateQuery}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" sm="9">
                  <Label className={"filter-label"}>Métricas:</Label>
                  <Row>{buildOptions()}</Row>
                  <br />
                  {metricsToDraw.length > 0 && !!allMetrics && (
                    <>
                      <Label className={"filter-label"}>
                        Métrica de Referencia:
                      </Label>
                      <Row>
                        <Col xs="3" sm="3">
                          <Input
                            type="select"
                            name="deviceID"
                            value={query.deviceID}
                            onChange={onUpdateQuery}
                          >
                            {metricsToDraw.map((md) => (
                              <option key={md} value={md}>
                                {md}
                              </option>
                            ))}
                          </Input>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>

                <Col xs="12" className="d-flex justify-content-end">
                  <Button
                    outline
                    type="button"
                    color="warning"
                    onClick={(e) => {
                      e.preventDefault();

                      // Reset query
                      setQuery({ ...baseQuery, deviceID: devices[0].deviceId });

                      // Clear charts
                      onClear();
                    }}
                  >
                    Limpiar
                  </Button>
                  <Button color="success" disabled={!query.dataGroupIDs.length}>
                    Graficar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}
