const BASE_API = "/api";

class Api {
  async getRTValues(deviceId) {
    const query = await fetch(`${BASE_API}/RealTime/${deviceId}`, {
      mode: "no-cors",
    });
    const data = await query.json();
    return data;
  }
  async getHistoryStateLogs(deviceId) {
    const query = await fetch(
      `${BASE_API}/History/GetAppStateLogs?deviceID=${deviceId}`
    );
    const data = await query.json();
    return data;
  }
  async getChartData(parameters) {
    let parametersList = [];
    for (let i = 0; i < parameters.length; i++) {
      parametersList.push("parameters=" + parameters[i]);
    }
    console.log(
      `${BASE_API}/History/GetAppChartValues/?` + parametersList.join("&")
    );
    const query = await fetch(
      `${BASE_API}/History/GetAppChartValues?` + parametersList.join("&")
    );
    const data = await query.json();
    return data;
  }
  async getEventsTimelineData(parameters) {
    let parametersList = [];
    for (let i = 0; i < parameters.length; i++) {
      parametersList.push("parameters=" + parameters[i]);
    }
    console.log(
      `${BASE_API}/History/GetAppChartValues/?` + parametersList.join("&")
    );
    const query = await fetch(
      `${BASE_API}/History/GetAppChartValues?` + parametersList.join("&")
    );
    const data = await query.json();
    return data;
  }
  async getParamChartInterval(deviceID, parameters, from, to) {
    let paramsList = [];
    paramsList.push("deviceID=" + deviceID);
    for (let i = 0; i < parameters.length; i++) {
      paramsList.push("parameters=" + parameters[i]);
    }
    paramsList.push("from=" + from);
    paramsList.push("to=" + to);

    console.log(
      `${BASE_API}/Analytics/GetParamChartData/?` + paramsList.join("&")
    );
    const query = await fetch(
      `${BASE_API}/Analytics/GetParamChartData?` + paramsList.join("&")
    );
    const data = await query.json();
    return data;
  }

  async getDevices() {
    const query = await fetch(`${BASE_API}/Device/GetDevices`);
    const data = await query.json();
    return data;
  }

  async getEventTypes() {
    const query = await fetch(`${BASE_API}/Device/GetEventTypes`);
    const data = await query.json();
    return data;
  }

  async getDataGroups() {
    const query = await fetch(`${BASE_API}/Analytics/GetDataGroups`);
    const data = await query.json();
    return data;
  }

  async getDataGroupChartData(params, endpoint) {
    const apiChartEndpoints = {
      general: "GetDataGroupChartData",
      minmax: "GetMinMaxChartData",
      rates: "GetRatesChartData",
    };
    const { deviceID, from, to, dataGroupID } = params;
    const paramsList = [];
    paramsList.push("deviceID=" + deviceID);
    paramsList.push("dataGroupID=" + dataGroupID);
    paramsList.push("from=" + from);
    paramsList.push("to=" + to);
    const query = await fetch(
      `${BASE_API}/Analytics/${apiChartEndpoints[endpoint]}?${paramsList.join(
        "&"
      )}`
    );
    const data = await query.json();
    return data;
  }

  async getReportData(params) {
    const { id, date } = params;

    const query = await fetch(
      `${BASE_API}/CFE/GetBill?contractID=${id}&date=${date}`
    );
    const data = await query.json();
    return data;
  }

  async getCFEContracts() {
    const query = await fetch(`${BASE_API}/CFE/GetContracts`);
    const data = await query.json();
    return data;
  }

  async getMulitAxisChartData(params) {
    const { deviceID, from, to, dataGroupIDs } = params;
    const paramsList = [];
    paramsList.push("deviceID=" + deviceID);
    paramsList.push("dataGroupIDs=" + dataGroupIDs);
    paramsList.push("from=" + from);
    paramsList.push("to=" + to);
    const query = await fetch(
      `${BASE_API}/Analytics/GetDataGroupMultiAxisChartData?${paramsList.join(
        "&"
      )}`
    );
    const data = await query.json();
    return data;
  }
}

export default new Api();
