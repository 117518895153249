import React, { useState } from "react";
import {
  ListGroupItem,
  ListGroup,
  Col,
  Spinner,
  Collapse,
  Card,
  CardBody,
} from "reactstrap";

export default function PumpSelector(props) {
  const { devices, selectedDevice, onSelectDevice, loading } = props;
  const [reveal, setReveal] = useState(false);

  return (
    <Col xs="12" md="6" className="mx-auto">
      {!loading && (
        <>
          <i
            className={`agt-icons mb-3 w-100 icon-minimal-${
              reveal ? "up" : "down"
            }`}
            onClick={() => setReveal((old) => !old)}
          />
          <Collapse isOpen={reveal}>
            <Card>
              <CardBody>
                <ListGroup style={{ border: "none" }}>
                  {devices.map((device) => (
                    <ListGroupItem
                      key={device.deviceId}
                      tag="button"
                      action
                      className="device-item-selector"
                      disabled={device.deviceId === selectedDevice.deviceId}
                      onClick={() => {
                        onSelectDevice(
                          devices.find((d) => d.deviceId == device.deviceId)
                        );
                        setReveal(false);
                      }}
                    >
                      {device.deviceId} - {device.name} - {device.localIp}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Collapse>
        </>
      )}
      {loading && (
        <Spinner
          color="light"
          className="position-absolute"
          style={{ top: "10px", right: "-2rem" }}
        />
      )}
    </Col>
  );
}
