/**
 * Store selected device
 * @param {*} deviceId
 */
export function storeSelectedDevice(deviceId) {
  localStorage.setItem("selectedDevice", deviceId);
}

export async function getSelectedDevice() {
  return localStorage.getItem("selectedDevice") || 4;
}
