import React from "react";
import { Row, Col } from "reactstrap";

import API from "../api";
import AnalyticsFilter from "../components/Analytics/AnalyticsFilter";
import MultiAxisChart from "../components/Analytics/MultiAxisChart";

class MultiAxis extends React.Component {
    state = {
        chartData: null,
        loading: false,
    };

    clearCharts = () => {
        this.setState({ chartData: null });
    };

    onQuery = (criteria) => {
        const { deviceID, from, to, dataGroupIDs } = criteria;

        // Loading charts
        this.setState({ loading: true });

        const query = { deviceID, from, to, dataGroupIDs };
        API.getMulitAxisChartData(query)
        .then((response) => {
            this.setState({ chartData: response, loading: false });
        })
        .catch((error) => {
            console.error("MultiAxis -> error", error);
            this.setState({ loading: false });
        });
    };

    render() {
        return (
        <>
            <div className="content">
            <Row>
                <Col xs="12">
                <AnalyticsFilter
                    onClear={this.clearCharts}
                    onSearch={this.onQuery}
                    allMetrics={true}
                />
                </Col>
                <Col xs="12">
                    {this.state.chartData &&
                        <MultiAxisChart
                            loading={this.state.loading}
                            chartData={this.state.chartData} />
                    }
                
                </Col>
            </Row>
            </div>
        </>
        );
    }
}

export default MultiAxis;
