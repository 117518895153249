

import React from "react";
import { TimeSeries } from "pondjs";

import {
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    LineChart,
    Baseline,
    Resizable,
    styler,
    Legend
} from "react-timeseries-charts";


class CrossHairs extends React.Component {
    render() {
        const { x, y } = this.props;
        const style = { pointerEvents: "none", stroke: "#ccc" };
        if (x !== null && y !== null) {
            return (
                <g>
                    <line style={style} x1={0} y1={y} x2={this.props.width} y2={y} />
                    <line style={style} x1={x} y1={0} x2={x} y2={this.props.height} />
                </g>
            );
        } else {
            return <g />;
        }
    }
}
class ParamChart extends React.Component {
    state = {
        dataGroup: this.props.dataGroup,
        chartData: this.props.chartData,
        key: this.props.key,
        tracker: null,
        timerange: {},
        series: undefined,
        categories:[],
        columns: [],
        tracker: null,
        x: null,
        y: null
    };

    componentDidMount() {
        this.setState({
            dataGroup: this.props.dataGroup,
            chartData: this.props.chartData,
            key: this.props.key,
            columns: this.props.chartData.legend,
            categories: this.buildCategories()
        }, () => { this.buildPoints()});
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.chartData !== this.props.chartData) {
            this.setState({
                dataGroup: this.props.dataGroup,
                chartData: this.props.chartData,
                key: this.props.key,
                columns: this.props.chartData.legend,
                categories: this.buildCategories()
            }, () => { this.buildPoints()});
        }
    }

    buildStyles = () => {
        const colors = ["steelblue", "red", "yellow", "green", "white"];
        const styles = [];
        this.state.columns.forEach((legend, i) => {
            styles.push({ key: legend, color: colors[i], width: 2 });
        });
        return styler(styles);
    }

    baselineStyle = {
        line: {
            stroke: "steelblue",
            strokeWidth: 1,
            opacity: 0.4,
            strokeDasharray: "none"
        },
        label: {
            fill: "steelblue"
        }
    };

    baselineStyleLite = {
        line: {
            stroke: "steelblue",
            strokeWidth: 1,
            opacity: 0.5
        },
        label: {
            fill: "steelblue"
        }
    };

    baselineStyleExtraLite = {
        line: {
            stroke: "steelblue",
            strokeWidth: 1,
            opacity: 0.2,
            strokeDasharray: "1,1"
        },
        label: {
            fill: "steelblue"
        }
    };

    buildCategories = () => {
        let categories = [];
        this.props.chartData.legend.forEach(line => {
            categories.push({
                key: line,
                label: <span style={{color: "white", paddingRight: "25px"}}>{line}</span>,
                disabled: false,
                labelStyle: {backgroundColor: "white"},
                active: true
            })
        });
        return categories;
    }

    dataCalcs(series) {
        const calcs = {
            max: () => {
                let maxValue;
                this.state.categories.filter(c => c.active).map(c => c.key).forEach(legend => {
                    maxValue = maxValue && series.max(legend) < maxValue ? maxValue : series.max(legend);
                });
                return maxValue;
            },
            min: () => {
                let minValue;
                this.state.categories.filter(c => c.active).map(c => c.key).forEach(legend => {
                    minValue = minValue && series.min(legend) > minValue ? minValue :  series.min(legend);
                });
                return minValue;
            },
            avg: () => {
                let avgValues = [];
                this.state.categories.filter(c => c.active).map(c => c.key).forEach(legend => {
                    avgValues.push(series.min(legend));
                });
                return (avgValues.reduce((a, b) => a + b, 0)) / avgValues.length;
            }
        }
        return calcs;
    }
    
    buildPoints = () => {
        const points = [];
        for (let i = 0; i < this.state.chartData.labels.length; i++) {
            let time = new Date(this.state.chartData.labels[i]);
            let values = [];
            for (let j = 0; j < this.state.chartData.datasets.length; j++) {
                values.push(this.state.chartData.datasets[j].data[i]);
            }
            points.push([time,...values]);
            //points.push([time, this.props.chartData.datasets[0].data[i]]);
        }
        const chartData = {
            // name: this.props.dataGroup.name,
            name: "",
            columns: ["time"].concat(this.state.categories.filter(c => c.active).map(c => c.key)),
            points
        }
        const series = new TimeSeries(chartData);
    
        this.setState({
            series,
            timerange: series.range(),
            dataCalcs: this.dataCalcs(series)
        });
    }
    handleMouseMove = (x, y) => {
        this.setState({ x, y });
    };

    handleTrackerChanged = tracker => {
        if (!tracker) {
            this.setState({ tracker, x: null, y: null });
        } else {
            this.setState({ tracker });
        }
    };

    handleActiveChange = key => {
        this.state.categories.find(c => c.key === key).active = !this.state.categories.find(c => c.key === key).active;
        this.setState({categories: this.state.categories});
    };


    handleTimeRangeChange = timerange => {
        const dataCalcs = this.dataCalcs(this.state.series.crop(this.state.timerange))
        this.setState({ timerange, dataCalcs });

    };
        
    render() {
        for (let i = 0; i < this.state.categories.length; i++) {
            if (this.state.tracker) {
                const index = this.state.series.bisect(this.state.tracker);
                const trackerEvent = this.state.series.at(index);
                this.state.categories[i].value = `${trackerEvent.get(this.state.categories[i].key)}`;
            }
        }
        return ( 
            <>
                {!this.state.series &&
                    <span>loading...</span>
                }
                {this.state.series &&
                <>
                    <div className="col-md-12">
                        <Legend
                            type="line"
                            style={this.buildStyles()}
                            type={"dot"}
                            categories={this.state.categories}
                            labelStyle={{backgroundColor: "white"}}
                            align={"right"}
                            onSelectionChange={this.handleActiveChange}
                        />
                    </div>
                    <Resizable>
                        <ChartContainer
                            title={this.state.series.name()}
                            titleStyle={{ fill: "#555", fontWeight: 500 }}
                            timeRange={this.state.timerange}
                            timeAxisTickCount={10}
                            enablePanZoom={true}
                            onTimeRangeChanged={this.handleTimeRangeChange}
                            maxTime={this.state.series.end()}
                            minTime={this.state.series.begin()}
                            onMouseMove={(x, y) => this.handleMouseMove(x, y)}
                            onTrackerChanged={this.handleTrackerChanged}
                        >
                            <ChartRow height="350">
                                <YAxis
                                    id="y"
                                    label={this.state.dataGroup.name}
                                    min={this.state.dataCalcs.min()}
                                    max={this.state.dataCalcs.max()}
                                    width="60"
                                    format=",.2f"
                                    style={{
                                        ticks: {
                                            stroke: "#AAA",
                                            opacity: 0.25,
                                            "stroke-dasharray": "1,1"
                                            // Note: this isn't in camel case because this is
                                            // passed into d3's style
                                        }
                                    }}
                                    showGrid
                                />
                                <Charts>
                                    <LineChart 
                                        axis="y" 
                                        series={this.state.series} 
                                        style={this.buildStyles()}
                                        columns={this.state.categories.filter(c => c.active).map(c => c.key)}
                                    />
                                    <CrossHairs x={this.state.x} y={this.state.y} />
                                    <Baseline
                                        axis="y"
                                        style={this.baselineStyleLite}
                                        value={this.state.dataCalcs.max()}
                                        label="Max"
                                        position="right"
                                    />
                                    <Baseline
                                        axis="y"
                                        style={this.baselineStyleLite}
                                        value={this.state.dataCalcs.min()}
                                        label="Min"
                                        position="right"
                                    />
                                    {/* <Baseline
                                        axis="y"
                                        style={this.baselineStyleExtraLite}
                                        value={this.chartData.series.avg("V1") - this.chartData.series.stdev("V1")}
                                    />
                                    <Baseline
                                        axis="y"
                                        style={this.baselineStyleExtraLite}
                                        value={this.chartData.series.avg("V1") + this.chartData.series.stdev("V1")}
                                    /> */}
                                    {/* <Baseline
                                        axis="y"
                                        style={this.baselineStyle}
                                        value={this.state.dataCalcs.avg()}
                                        label="Avg"
                                        position="right"
                                    /> */}
                                </Charts>
                            </ChartRow>
                        </ChartContainer>
                    </Resizable>
                </>
                }
            </>
        );
    }
}

export default ParamChart;