import React from "react";
import { Row, Col, Button, Spinner, UncontrolledAlert } from "reactstrap";
import MQTT from "mqtt";
// var ca = require('../certs/ca.crt');
// var cert = require('../certs/client.crt');
// var key = require('../certs/client.key');

import API from "api";
import { getSelectedDevice, storeSelectedDevice } from "storage";

import PumpMonitor from "components/PumpControl/PumpMonitor";
import PumpSelector from "components/PumpControl/PumpSelector";
import PumpRTValues from "components/PumpControl/PumpRTValues";
import PumpRTValuesMBIP from "components/PumpControl/PumpRTValuesMBIP";
import PumpButtons from "components/PumpControl/PumpButtons";


const pumpButtonsStates = {
  "000-": ["off", "off", "off"],
  "100-": ["on", "off", "off"],
  "110-": ["on", "on", "off"],
  "111-": ["on", "on", "on"],
  "011-": ["off", "on", "on"],
  "001-": ["off", "off", "on"]
};

function uint8arrayToString(myUint8Arr) {
  return String.fromCharCode.apply(null, myUint8Arr);
}

const eventTypeAlarms = {
  1: 'alarm1',
  2: 'alarm2',
  O: 'overload'
}

class PumpController extends React.Component {
  state = {
    mqttClient: {},
    pumpState: "",
    loading: true,
    btnStates: ["", "", ""],
    devicesLoading: true,
    devices: [],
    selectedDevice: null,
    alarms: "",
    processAlarms: "",
    eventTypes:[]
  };

  createClient = () => {
    const client = MQTT.connect("ws://34.125.103.226:8083/mqtt", {
      clientId:
        "pumpMonitorWeb" +
        Math.random()
          .toString(36)
          .substring(7),
      // rejectUnauthorized: false,
      // ca:'-----BEGIN CERTIFICATE-----MIIDazCCAlOgAwIBAgIUBvKYyt1x0lpXNZhg0TOZE0BP6hwwDQYJKoZIhvcNAQELBQAwRTELMAkGA1UEBhMCQVUxEzARBgNVBAgMClNvbWUtU3RhdGUxITAfBgNVBAoMGEludGVybmV0IFdpZGdpdHMgUHR5IEx0ZDAeFw0yMjA5MDExOTM4MzBaFw0yODAyMjIxOTM4MzBaMEUxCzAJBgNVBAYTAkFVMRMwEQYDVQQIDApTb21lLVN0YXRlMSEwHwYDVQQKDBhJbnRlcm5ldCBXaWRnaXRzIFB0eSBMdGQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDhEi8tw01ebmgw18+AknabCHj72CjCfT9vrxTmSrZvIsT/oZVVKhBh0rE9weJRd7H424YAWV8lEaPMReqmLqmYo4QbrOCDFyV9ZmY2HDyizLZKi4poId0pL8n5dIYygAWcwu0s7vTU4mQA8qkvlniNENXVpYdOOIf3Onv+2TVfJiSrbT/cGkQvkOjciSMKjmK9pC6yo1Xd00ImS7pAsEHJXvA2uIAqJ1AshzRLSUERAoy4xFi0FJnXBdlv4DyUrnNBaJnjhNTYZ9Btb5eeKdlwFMWc517oy+OAqI2scPN/IikrPiUQxnWFPGP3Xzv8GckvLMN5M7hlF5BYHKTRS6IlAgMBAAGjUzBRMB0GA1UdDgQWBBR/PQWoR14zuPSVJ++KdaNdpRXgMTAfBgNVHSMEGDAWgBR/PQWoR14zuPSVJ++KdaNdpRXgMTAPBgNVHRMBAf8EBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQBTTcXOh+d9VQzaUtouhliA2uCAJmjRJ1zBRHgvpMfgZj9D15MtZ7XfVmMpDvOLchPTvcYbMWdqf31q/dXyhV5mozgWeJ6AvP3aiXzhjLxMBAgzOTN+7IodEKu0UK+CwAQnjgfvgjkCuI/C/PguPTrng/q9SNARKBt/InfybsJp99DWztYpPfGBotq/zBYhZYuom7QZvMUWG4fbXXTsoI0yLDvzgc4tPqnQ6Irj6r11dw0B1RRypiTV5109BNScG3RIxtJH/eb0H1oJDJldhyqAhRyIK+xfT3wyDlseEvQBWSg0GHHcLB3oraK33Lbdt3YZGTcwduS54xaQqiLgiaSG-----END CERTIFICATE-----',
      // cert: '-----BEGIN CERTIFICATE-----MIIDIzCCAgsCFDR793EeYUXLXF9P795GOlEGRj74MA0GCSqGSIb3DQEBCwUAMEUxCzAJBgNVBAYTAkFVMRMwEQYDVQQIDApTb21lLVN0YXRlMSEwHwYDVQQKDBhJbnRlcm5ldCBXaWRnaXRzIFB0eSBMdGQwHhcNMjIwOTAxMTk1MjM4WhcNMjUwNTI4MTk1MjM4WjBXMQswCQYDVQQGEwJBVTETMBEGA1UECAwKU29tZS1TdGF0ZTEhMB8GA1UECgwYSW50ZXJuZXQgV2lkZ2l0cyBQdHkgTHRkMRAwDgYDVQQDDAdkZXZpY2UxMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApMorPK0dJX2hx3qCYXnCaYcxDqnaJLmZKh8hbqaDaH4U+WI6UxFv6U3aJlnyE6oudBnem31gPYuuOAMEa5IsOD+Z2ALW4T5zprAMqTFJiwIxI89FVgWQosBDDQZTue9CuQbE0zsobkh9XBP8uGNEXz7/YiDETE96xuh1iauk9sCsTyVYnuy19c+gs2UoLcrA+/eKRteXa6/z9MucJ//aPRuDMAwvA5viRn7HaA68VSZofB4YQpZeeIqj+U/03DOsFheCE+ClkZ6QtREFgcEIGC7VBQoyL1q0JVypyRvmy5dbcKNGR5aR/c0+09SZgLI28DKMwi35XIUNivXWFRw3cQIDAQABMA0GCSqGSIb3DQEBCwUAA4IBAQBHJS0x8PjBOqy1Py0JU48FpH/XLOWtBc7eSf0bNh/lcEDrzUHlZ5a4/oGDxtnotbWAudm44Bqor4c+zQe8e+qnVE7g4v1uHmlI4EK/oj8HbR4E5ykjLFkDxFQMgDl0ItkuVbrbln+ZeKmuEA1GIUZ5Qr/x2r2DnJO2/Yxzsqf4MoWiFxGQ+VRp3yT9OPanEPUqjoPjEHC48mS2eSM1hNhfIGHQ4ioiqXk0HeMcXwfmoYJNQEeCNTqy19aO6T9PLyrgp8U+4HcGTxWmNVBIvS8ViH2y4mU37gLpfUwui9yIyVhuTAVxC2bZa5tqLWwKrAooETG2GIxV7HLCZsQ3I16h-----END CERTIFICATE-----',
      // key: '-----BEGIN RSA PRIVATE KEY-----MIIEowIBAAKCAQEApMorPK0dJX2hx3qCYXnCaYcxDqnaJLmZKh8hbqaDaH4U+WI6UxFv6U3aJlnyE6oudBnem31gPYuuOAMEa5IsOD+Z2ALW4T5zprAMqTFJiwIxI89FVgWQosBDDQZTue9CuQbE0zsobkh9XBP8uGNEXz7/YiDETE96xuh1iauk9sCsTyVYnuy19c+gs2UoLcrA+/eKRteXa6/z9MucJ//aPRuDMAwvA5viRn7HaA68VSZofB4YQpZeeIqj+U/03DOsFheCE+ClkZ6QtREFgcEIGC7VBQoyL1q0JVypyRvmy5dbcKNGR5aR/c0+09SZgLI28DKMwi35XIUNivXWFRw3cQIDAQABAoIBAQCcPPHxfVB9EzgnoQes03x4AS2J8ZBZ8X4+aXWRK167246XQ4J477djQ+TpJ65U5q1UdpKQabato5lykanHM7epPOPn4glJVaThDVlzsL9BhLqCcSoT/QK8y/zGhJEtYKOqObizbQtQRHpWqryZNcTFaT1JFDgJAfIuVIfcF1k4eL87WqpuTQvaMvEemx2bMvkQNyNzIptMERPtOgkY8qCPzDRxf9396WQxYvDIDRMjRFJfPfmRqxFOZ22OLampFp619byQSnoB2Lv33TX4G9uLizOOvZbAM+berzK1l+8dwD4kgi7PPpc7DhYJQKApqrXtj0Q7dBeaKb38n00L99iBAoGBANPzIKe0KlAyqxy6ekwLvX/7hD62mtAIUm+sWPa56FLdflZvgHLPOhVpX7LWX2ntFdiSICzmmVL7TFrwPVQYLinKU4R2hbv8IBDcvDOp6LCzwwJjbegB6oDlTsWcqSSi1YqL4l1NtB0yTAwo4f0TYgCmpI5pwaJwtjTULOiucQF1AoGBAMcJ39FpXFg4Bgv94H48rlg5s3P+9TqwAxuLEXy2UyWrkSjwMCysU2vJBPeVv20aSAsi+mwaNtgqHYG8hseQkeLh+o/dutly3/iTLS2EDafRKUfDUuX0kkjcfXzsXPxG83h5ssGulPDRBSHT9JUzBz2Jkw/eb+L5pUiNyzpIfYKNAoGAdY6+qAX4Ci9wVSjhOFiyrM/Wk+o4h/Z1stcHKSIISB3uOwbxWbyy3LlriQf6OAh2O38hNYZZuHsF6NKjUYdv42IDXM9YceHv7ypfgNBW7dtm6GY5yZt2dDO7s0OnVViz1Hqt/U5h/metYnkyj2o2y2GimBL0gaK+jSK6HfzTVRkCgYByCnhRFhsw+LiOOmJcMgx6xGTLu3Nqstq091OHrzeox8qeODCCCCKsCTttWEGcE4jfyKTPN53g8RCC+zAGPBE6e9wCfjrGyIkHddxWzei9FJygX4up8h223Y6Q+ySz7zsk9iWzz4HLERftr/HdYqVtXtwCJaLZ28wV7BhbRpmTKQKBgEJv76cFqcOBMVYi05C4py0pc2Imxr77RIrLiWBimwRvX+4UDZG3P48OFlVqqi8hs6jYApZ3Y74M23mCf0z3bFHhPS5Nm+LLzxbBfqJ5VoTChy1rGZvHwejoPbQlruOIxjWCAjzC8b0jqZJRwdNSWtOce+SrqataGg9Ox1RNwCLw-----END RSA PRIVATE KEY-----',
    });

    client.on("connect", () => {
      console.log("mqtt.event.connected");
      // client.subscribe("2/5mC82B96B8/pumpAction", 0);
      // client.subscribe("2/5mC82B96B8/pumpState", 0);
      // client.publish("2/5mC82B96B8/getState", "on", 2, false);
      client.subscribe(this.state.selectedDevice.topics.pumpAction, 0);
      client.subscribe(this.state.selectedDevice.topics.pumpState, 0);
      client.publish(this.state.selectedDevice.topics.getState, "on", 2, false);
      this.setState({
        pumpState: "",
        btnStates: ["", "", ""],
        loading: false,
        mqttClient: client,
      });
    });

    client.on("message", (_, data) => {
      const msg = uint8arrayToString(data);
      if (msg.indexOf("pumpState") > -1) {
        const states = msg.replace("pumpState", "").substring(0, 4);
        const alarms = msg.substring(14).split('-')[0];
        const processAlarms = msg.substring(14).split('-')[ 1 ];        
        this.setState({
          pumpState: states,
          btnStates: pumpButtonsStates[states],
          alarms,
          processAlarms,
          loading: false
        });
      }
    });

    client.on("closed", () => {
      console.log("mqtt.event.closed");
    });
    client.on("error", msg => {
      console.log("mqtt.event.error", msg);

      this.setState({
        btnStates: ["", "", ""],
        loading: false,
      });
    });

    return client;
  };



  setupEngine = value => {
    // this.state.mqttClient.publish("2/5mC82B96B8/pumpAction", value, 2, false);
    this.state.mqttClient.publish(
      this.state.selectedDevice.topics.pumpAction,
      value,
      2,
      false
    );
  };

  onTogglePumpButton = value => {
    switch (value) {
      case "on3":
        if (this.state.pumpState.indexOf("110") > -1) {
          this.setupEngine(value);
        }
        break;
      case "off3":
        if (this.state.pumpState.indexOf("001") > -1) {
          this.setupEngine(value);
        }
        break;
      case "on2":
        if (
          this.state.pumpState.indexOf("100") > -1 ||
          this.state.pumpState.indexOf("001") > -1
        ) {
          this.setupEngine(value);
        }
        break;
      case "off2":
        if (
          this.state.pumpState.indexOf("011") > -1 ||
          this.state.pumpState.indexOf("110") > -1
        ) {
          this.setupEngine(value);
        }
        break;
      case "on1":
        if (
          this.state.pumpState.indexOf("000") > -1 ||
          this.state.pumpState.indexOf("011") > -1
        ) {
          this.setupEngine(value);
        }
        break;
      case "off1":
        if (
          this.state.pumpState.indexOf("100") > -1 ||
          this.state.pumpState.indexOf("111") > -1
        ) {
          this.setupEngine(value);
        }
        break;
      default:
        this.setupEngine(value);
        break;
    }
  };

  async getChartData(params) {
    const colors = ['#1f72bf', '#09692f', '#6e260c', '#a0a805'];
    let data = await API.getChartData(
      this.state.selectedDevice.deviceId,
      params,
    );
    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].color = () => {
        return colors[i];
      };
    }
    this.setState({chartData: data, loadingChartData: false});
    console.log(data);
  }

  componentDidMount = async () => {
    this.setState({ devicesLoading: true });
    // Get all devices and set a selected device
    const previousDeviceId = await getSelectedDevice();
    let devices = await API.getDevices();

    devices = devices.map(d => {
      return {
        ...d,
        topics: this.getDeviceTopics(d.deviceTopics)
      };
    });

    let selectedDevice = devices.find(d => d.deviceId == previousDeviceId);
    if (!selectedDevice) selectedDevice = devices[ 0 ];
    let eventTypes = await API.getEventTypes();

    this.setState(
      {
        devices,
        selectedDevice,
        devicesLoading: false,
        eventTypes
      },
      () => {
        // After get devices, then create the client using the selected device
        const client = this.createClient();
        this.setState({ mqttClient: client });
      }
    );
  };

  getDeviceTopics = (deviceTopics) => {
    return deviceTopics.reduce((topics, currentDeviceTopic) => {
      const [, , topic] = currentDeviceTopic.topic.split("/");
      return {
        ...topics,
        [topic]: currentDeviceTopic.topic,
      };
    }, {});
  };

  onUpdateDevice = (device) => {
    this.state.mqttClient.end(true, {}, () => {
      this.setState({ selectedDevice: device, loading: true }, () => {
        storeSelectedDevice(device.deviceId);
        const client = this.createClient();
        this.setState({ mqttClient: client });
      });
    });
  };

  render() {
    if (!this.state.btnStates) return null
    debugger
    return (
      <>
        {this.state.selectedDevice &&
          <div className="content">
            <Row className="justify-center mb-3">
              <Col xs="12" className="text-center">
                {
                  <h1>
                    {this.state.selectedDevice && this.state.selectedDevice.name}
                  </h1>
                }
                {this.state.devices.length > 1 && (
                  <PumpSelector
                    selectedDevice={this.state.selectedDevice || {}}
                    devices={this.state.devices}
                    onSelectDevice={this.onUpdateDevice}
                    loading={this.state.devicesLoading}
                  />
                )}
                {this.state.alarms.length > 0  &&
                  this.state.alarms.split("").map(a => {
                    return (
                      <UncontrolledAlert color="danger">
                        <span>
                          <b>{this.state.eventTypes.filter(e => e.type === eventTypeAlarms[ a ])[0].description}</b>
                        </span>
                      </UncontrolledAlert>
                    )
                  })
                }
                {this.state.processAlarms &&
                  this.state.processAlarms.split("").map(a => {
                    return (
                      <UncontrolledAlert color="warning">
                        <span>
                          <b>{this.state.eventTypes.filter(e => e.type === ('alarmIP' + a))[0].description}</b>
                        </span>
                      </UncontrolledAlert>
                    )
                  })
                }
                <PumpMonitor
                  pumpState={this.state.pumpState}
                  loading={this.state.loading}
                />
              </Col>
              <PumpButtons
                buttonsState={this.state.btnStates}
                onClick={this.onTogglePumpButton}
              />
            </Row>
            <Row className="mt-5 justify-center remove-margin-top">
              <Col xs="12" className="mt-5">
                {this.state.selectedDevice &&
                  <PumpRTValues device={this.state.selectedDevice} />
                }
              </Col>
            </Row>
          </div>
        }
      </>
    );
  }
}

export default PumpController;
