import Dashboard from "views/Dashboard";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import UserProfile from "views/UserProfile.js";
import Reports from "views/Reports.js";
import Settings from "views/Settings.js";

// Pump pages
import Historical from "views/Historical.jsx";
import Configuration from "views/Configuration.js";
import PumpController from "views/PumpController";
import MultiAxis from "views/MultiAxis";

var routes = [
  {
    path: "/controller",
    name: "Control",
    rtlName: "Controller",
    icon: "agt-icons icon-button-power",
    component: PumpController,
    layout: "/admin",
  },
  {
    path: "/historical",
    name: "Historicos",
    rtlName: "Analytics",
    icon: "agt-icons icon-chart-pie-36",
    component: Historical,
    layout: "/analytics",
  },
  {
    path: "/multiaxis",
    name: "Analiticos",
    rtlName: "Analytics",
    icon: "agt-icons icon-chart-pie-36",
    component: MultiAxis,
    layout: "/analytics",
  },
  {
    path: "/alarms",
    name: "Alarmas",
    rtlName: "Alarms",
    icon: "agt-icons icon-alert-circle-exc",
    component: Configuration,
    layout: "/admin",
  },
  {
    path: "/grafics",
    name: "Graficas",
    rtlName: "Grafics",
    icon: "agt-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/analytics",
    hideRoute: true,
  },
  {
    path: "/reports",
    name: "Factura CFE",
    rtlName: "Reports",
    icon: "agt-icons icon-paper",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Iconos",
    rtlName: "Icons",
    icon: "agt-icons icon-paper",
    component: Icons,
    layout: "/admin",
    hideRoute: true,
  },
  {
    path: "/settings",
    name: "Configuraciones",
    rtlName: "Settings",
    icon: "agt-icons icon-settings-gear-63",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Dispositivos",
    rtlName: "Devices",
    icon: "agt-icons icon-square-pin",
    component: Typography,
    layout: "/admin",
    hideRoute: true,
  },
  {
    path: "/user-profile",
    name: "Usuarios",
    rtlName: "Users",
    icon: "agt-icons icon-single-02",
    component: UserProfile,
    layout: "/admin",
    hideRoute: true,
  },

  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "agt-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl"
  // }
];
export default routes;
