import React, { useEffect, useState } from "react";
import { Table, Spinner, Card, CardTitle, CardHeader } from "reactstrap";
import RTChart from "components/PumpControl/PumpRTChart";
import PumpRTValuesMBIP from "./PumpRTValuesMBIP";
import API from "api";

export default function PumpRTValues(props) {
  const { device } = props;
  const [isLoading, setLoading] = useState(true);
  const [ rtValues, setRtValues ] = useState();
  const [rtMBIPValues, setRtMBIPValues] = useState();
  const [metricsToChart, setMetricsToChart] = useState([32, 33, 34]); // aqui es por mapID
  const [chartLabels, setChartLabels] = useState([]);
  const [chartValues, setChartValues] = useState([]);
  const [chartMetricTitle, setChartMetricTitle] = useState("");
  const [chartSeries, setChartSeries] = useState([]);



  const selectedMetrics = [
    [31, 32, 33],
    [0, 8, 16],
    [1, 9, 17]
  ];
  const listMetrics = [2, 3, 4, 5, 6, 7];

  function selectMetricsToChart(e) {
    const metric = e.currentTarget.innerText.split("\n")[0];
    const metricMapID = rtValues.filter(r => r.parameter === metric).map(m => m.mapID);
    setMetricsToChart(metricMapID);
    setChartValues([]);
    setChartLabels([]);
    setChartSeries([]);
  }


  useEffect(() => {

    function addChartData(data) {
      let valuesSet = [];
      let chartTitle = "";
      let series = [];
      for(let i = 0; i < metricsToChart.length; i++) {
        const val = data.values.find(v => v.mapID == metricsToChart[i]);
        valuesSet.push(val.value);
        series.push(val.simbol);
        chartTitle = val.parameter;
      }
      setChartLabels(chartLabels => [...chartLabels, new Date(data.timeRead).toLocaleTimeString()]);
      setChartValues(chartValues => [...chartValues, valuesSet]);
      setChartSeries(series);
      setChartMetricTitle(chartTitle);
    }

    async function fetchRtValues() {
      const data = await API.getRTValues(device.deviceId);
      addChartData(data);
      setRtValues(data.values);
      setRtMBIPValues(data.valuesMBIP);
      setLoading(false);
    }

    let interval;

    setLoading(true);
    clearInterval(interval);
    interval = setInterval(() => {
      fetchRtValues();
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [device, metricsToChart]);

  return isLoading ? (
    <div  xs="12" className="mt-5 text-center">
      <Spinner color="light" />
    </div>
  ) : (
      <>
        {rtMBIPValues &&
          <PumpRTValuesMBIP data={rtMBIPValues} />
        }
        <Card>
          <CardHeader>
              <CardTitle tag="h4"><span className="text-success h2">Calidad de la Energía</span></CardTitle>
          </CardHeader>
          <Table responsive>
            <tbody>
              {!!rtValues &&
                selectedMetrics.map((metric, idx) => (
                  <tr key={idx} onClick={(e) => selectMetricsToChart(e)}>
                    <td className="d-none d-sm-block">
                      <span className="text-white h3">{rtValues[metric[0]].parameter}</span>
                    </td>
                    {metric.map((value, i) => (
                      <td key={value + i} colSpan={i}>
                        <span className="text-white h1">{rtValues[value].value}</span>
                        <span className="text-success h4">
                          {rtValues[value].simbol.replace("\n", "")}
                        </span>{" "}
                      </td>
                    ))}
                  </tr>
                ))}
              {!!rtValues &&
                listMetrics.map((value) => (
                  <tr key={rtValues[value].mapID} onClick={(e) => selectMetricsToChart(e)}>
                    <td className="d-none d-sm-block">
                      <span className="text-white h3">{rtValues[value].parameter}</span>
                    </td>
                    <td>
                      <span className="text-white h1">{rtValues[value].value}</span>
                      <span className="text-success h4">
                        {rtValues[value].simbol.replace("\n", "")}
                      </span>
                    </td>
                    <td>
                      <span className="text-white h1">
                        {rtValues[value + 8].value}
                      </span>
                      <span className="text-success h4">
                        {rtValues[value + 8].simbol.replace("\n", "")}
                      </span>
                    </td>
                    <td>
                      <span className="text-white h1">
                        {rtValues[value + 16].value}
                      </span>
                      <span className="text-success h4">
                        {rtValues[value + 16].simbol.replace("\n", "")}
                      </span>
                    </td>
                    <td>
                      <span className="text-white h1">
                        {rtValues[value + 22].value}
                      </span>
                      <span className="text-success h4">
                        {rtValues[value + 22].simbol.replace("\n", "")}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
        <br />
        <hr />
        {chartValues.length > 0 &&
          <div style={{height: '500px'}} >
            <RTChart 
            labels={chartLabels}
            values = {chartValues}
            chartTitle={chartMetricTitle}
            series={chartSeries} />
          </div>
        }
        <br /><br /><br />
    </>
  );
}
