import React from "react";
import { Col, FormGroup, Label, Input } from "reactstrap";

export default function EndpointSelector({ onSelectEndpoint }) {
  return (
    <Col
      xs={12}
      md={{
        offset: 4,
        size: 4,
      }}
    >
      <FormGroup>
        <Label for="exampleSelect">Selecciona el tipo</Label>
        <Input
          name="endpoint"
          type="select"
          onChange={(e) => onSelectEndpoint(e.target.value)}
        >
          <option value="general">General</option>
          <option value="minmax">Maximos y Minimos</option>
          <option value="rates">Analisis por tarifa</option>
        </Input>
      </FormGroup>
    </Col>
  );
}
