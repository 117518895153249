import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button, Col, Row } from "reactstrap";

const variableCodes = {
  "1": "Tensión Fase-Neutro-L1",
  "9": "Tensión Fase-Neutro-L2",
  "17": "Tensión Fase-Neutro-L3",
  "2": "Corriente-L1",
  "10": "Corriente-L2",
  "18": "Corriente-L3",
  "3": "Potencia Activa-L1",
  "11": "Potencia Activa-L2",
  "19": "Potencia Activa-L3",
  "25": "Potencia Activa-III",
  "4": "Potencia Reactiva Inductiva-L1",
  "12": "Potencia Reactiva Inductiva-L2",
  "20": "Potencia Reactiva Inductiva-L3",
  "26": "Potencia Reactiva Inductiva-III",
  "5": "Potencia Reactiva Capacitiva-L1",
  "13": "Potencia Reactiva Capacitiva-L2",
  "21": "Potencia Reactiva Capacitiva-L3",
  "27": "Potencia Reactiva Capacitiva-III",
  "6": "Potencia Aparente-L1",
  "14": "Potencia Aparente-L2",
  "22": "Potencia Aparente-L3",
  "28": "Potencia Aparente-III",
  "7": "Factor de potencia-L1",
  "15": "Factor de potencia-L2",
  "23": "Factor de potencia-L3",
  "29": "Factor de potencia-III",
  "8": "Coseno φ-L1",
  "16": "Coseno φ-L2",
  "24": "Coseno φ-L3",
  "30": "Coseno φ-III",
  "36": "% THD V-L1",
  "37": "% THD V-L2",
  "38": "% THD V-L3",
  "200": "Tensión Fase-Neutro",
  "201": "Corriente",
  "202": "Potencia Activa",
  "206": "Tensión Fase-Fase"
};

const baseConfigs = {
  max: "",
  min: "",
  variableCode: "",
  delayConnect: 0,
  histeresis: 0,
  latch: false,
  delayDisconnect: 0,
  contactState: false
};

export default function AlarmForm(props) {
  const { onSubmit, configs } = props;
  const [query, setQuery] = useState(baseConfigs);

  useEffect(() => {
    setQuery(configs);
  }, []);

  const onUpdateQuery = e => {
    const { value, name, checked } = e.target;
    switch (name) {
      case "latch":
      case "contactState":
        // Data group id need to handle checked/unchecked state
        setQuery({ ...baseConfigs, ...query, [name]: checked });
        break;
      default:
        setQuery({ ...baseConfigs, ...query, [name]: value });
    }
  };
  
  return (
      <Form
        className="alarm-form"
        onSubmit={e => {
          e.preventDefault();
          onSubmit(query);
        }}
      >
        <Row>
        <Col xs="12" md="8">
            <FormGroup>
              <Label>Codigo de la variable</Label>
              <Input
                type="select"
                name="variableCode"
                onChange={onUpdateQuery}
                value={query.variableCode}
              >
                {Object.keys(variableCodes).map(k => (
                  <option key={variableCodes[k]} value={k}>
                    {variableCodes[k]}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col xs="12" md="4">
            <FormGroup>
              <Label>Histeresis</Label>
              <Input
                type="number"
                min="0"
                max="99"
                value={query.histeresis}
                onChange={onUpdateQuery}
                name="histeresis"
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup>
              <Label>Valor Maximo</Label>
              <Input
                type="number"
                value={query.max}
                name="max"
                onChange={onUpdateQuery}
                placeholder="Segun variable"
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup>
              <Label>Valor minimo</Label>
              <Input
                type="number"
                value={query.min}
                name="min"
                onChange={onUpdateQuery}
                placeholder="Segun variable"
              />
            </FormGroup>
          </Col>

          <Col xs="12" md="6">
            <FormGroup>
              <Label>Retardo de la conexion</Label>
              <Input
                type="number"
                min="0"
                max="9999"
                onChange={onUpdateQuery}
                value={query.delayConnect}
                name="delayConnect"
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup>
              <Label>Retardo de la desconexion</Label>
              <Input
                type="number"
                min="0"
                max="9999"
                onChange={onUpdateQuery}
                value={query.delayDisconnect}
                name="delayDisconnect"
              />
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={query.latch}
                    onChange={onUpdateQuery}
                    name="latch"
                  />
                  <span className="form-check-sign">
                    <span className="check" />
                  </span>
                  Enclavamiento (Latch)
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label>Estado de los contactos:</Label>
                <br />
                <Label check>
                  <Input
                    type="checkbox"
                    onChange={onUpdateQuery}
                    checked={query.contactState}
                    name="contactState"
                  />
                  <span className="form-check-sign">
                    <span className="check" />
                  </span>
                  Normalmente abierto
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col xs="12" className="mt-3">
            <Button>Enviar</Button>
          </Col>
        </Row>
      </Form>
  );
}
