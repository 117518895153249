import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';


const options = {
    scales: {
        yAxes: [
        {
            ticks: {
            beginAtZero: true,
            },
        },
        ],
    },
    maintainAspectRatio: false,
};
const colors = ['#00f2c3', '#007bff', '#ffc107', '#f5365c'];

const RTChart = props => {
    let datasets = [];
    for (let i = 0; i < props.values[0].length; i++) {
        datasets.push({
            label: props.series[i],
            data: [],
            fill: false,
            backgroundColor: colors[i],
            borderColor: colors[i]
        });
    }
    for (let i = 0; i < props.values.length; i++) {
        for (let j = 0; j < datasets.length; j++) {
            datasets[j].data.push(props.values[i][j]);
        }
    }
    const d = {
        labels: props.labels,
        datasets: datasets
    }

    return(
    <>
        <div className='header'>
        <h1 className='title'>{props.chartTitle}</h1>
        </div>
        <Line data={d} options={options} />
    </>
    )
};

export default RTChart;