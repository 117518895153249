import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import MQTT from "mqtt";
import AlarmForm from "components/Configurations/AlarmForm";
import API from "api";
import { getSelectedDevice, storeSelectedDevice } from "storage";
import PumpSelector from "components/PumpControl/PumpSelector";

function uint8arrayToString(myUint8Arr) {
  return String.fromCharCode.apply(null, myUint8Arr);
}
class Configuration extends React.Component {

  state = {
    mqttClient: {},
    pumpState: "",
    loading: true,
    btnStates: ["", "", ""],
    devicesLoading: true,
    devices: [],
    selectedDevice: null,
    loadingAlarms: true,
    alarmData1: {},
    alarmData2: {}
  };
  modbusAddress = { 1: "2AF8", 2 : "2B02" };

  onSearchAlarm = (query, alarmId) => {
    let strValues = Object.values(query).toString();
    strValues = strValues.replaceAll("true", "1").replaceAll("false", "0");
    const values = strValues.split(',');
    const maxVal = (parseFloat(values[0]) * 10).toString(16).padStart(8, "0");
    const minVal = (parseFloat(values[1]) * 10).toString(16).padStart(8, "0");
    values.splice(0, 2);
    const max1 = parseInt(maxVal.substring(0,4), 16);
    const max2 = parseInt(maxVal.substring(4), 16);
    const min1 = parseInt(minVal.substring(0,4), 16);
    const min2 = parseInt(minVal.substring(4), 16);
    values.splice(0, 0, max1);
    values.splice(1, 0, max2);
    values.splice(2, 0, min1);
    values.splice(3, 0, min2);
    console.log("Configuration -> onSearchAlarm -> data", values);
    const config =  this.modbusAddress[alarmId] + "," + "10," + values.join('|')
    this.state.mqttClient.publish(this.state.selectedDevice.topics.config + "/writeAlarmConfigs", config, 2, false);
    //this.readAlarms();
  };

  readAlarms = () => {
    this.state.mqttClient.publish(this.state.selectedDevice.topics.config + "/readAlarmConfigs", "all", 2, false);
  }

  alarmDataBuilder = (rawData) => {
    const data = rawData.split(',');
    const alarmData1 = {
      max: parseInt(parseInt(data[0]).toString(16) + parseInt(data[1]).toString(16), 16)/10,
      min: parseInt(parseInt(data[2]).toString(16) + parseInt(data[3]).toString(16), 16)/10,
      variableCode: data[4],
      delayConnect: parseInt(data[5]),
      histeresis: parseInt(data[6]),
      latch: data[7] == 1,
      delayDisconnect: parseInt(data[8]),
      contactState: data[9] == 1
    };
    const alarmData2 = {
      max: parseInt(parseInt(data[10]).toString(16) + parseInt(data[11]).toString(16), 16)/10,
      min: parseInt(parseInt(data[12]).toString(16) + parseInt(data[13]).toString(16), 16)/10,
      variableCode: data[14],
      delayConnect: parseInt(data[15]),
      histeresis: parseInt(data[16]),
      latch: data[17] == 1,
      delayDisconnect: parseInt(data[18]),
      contactState: data[19] == 1
    };
    console.log("alarmData1:", alarmData1);
    console.log("alarmData2:", alarmData2);
    return [alarmData1, alarmData2]
  }


  createClient = () => {
    const client = MQTT.connect("ws://34.125.103.226:8083/mqtt", {
      clientId:
        "pumpMonitorWeb" +
        Math.random()
          .toString(36)
          .substring(7),
      tls: false,
      auth: false
    });

    client.on("connect", () => {
      console.log("mqtt.event.connected");
      client.subscribe(this.state.selectedDevice.topics.config + "/#", 0);
      this.setState({
        loading: false,
        mqttClient: client,
      });
    });

    client.on("message", (topic, data) => {
      const msg = uint8arrayToString(data);
      console.log("Configurations -> ", msg);
      if (topic.indexOf("config/AlarmConfigs") > -1) {
        const alarms = this.alarmDataBuilder(msg);
        this.setState({
          alarmData1: alarms[0],
          alarmData2: alarms[1],
          loadingAlarms: false
        });
      }
      if (topic.indexOf("config/savedAlarm") > -1) {
        alert(msg);
        //this.readAlarms();
      }
    });

    client.on("closed", () => {
      console.log("mqtt.event.closed");
    });
    client.on("error", msg => {
      console.log("mqtt.event.error", msg);

      this.setState({
        btnStates: ["", "", ""],
        loading: false,
      });
    });

    return client;
  };

  componentDidMount = async() => {
    this.setState({ devicesLoading: true });
    
    const previousDeviceId = await getSelectedDevice();
    let devices = await API.getDevices();
    devices = devices.map(d => {
      return {
        ...d,
        topics: this.getDeviceTopics(d.deviceTopics)
      };
    });
      const client = this.createClient();
      let selectedDevice = devices.find(d => d.deviceId == previousDeviceId);
      if (!selectedDevice) selectedDevice = devices[0]
    this.setState({
      mqttClient: client,
      devices,
      selectedDevice,
      devicesLoading: false 
    }, () => {
      this.readAlarms();
    });
  }

  getDeviceTopics = (deviceTopics) => {
    return deviceTopics.reduce((topics, currentDeviceTopic) => {
      const [, , topic] = currentDeviceTopic.topic.split("/");
      return {
        ...topics,
        [topic]: currentDeviceTopic.topic,
      };
    }, {});
  };

  onUpdateDevice = (device) => {
    this.state.mqttClient.end(true, {}, () => {
      this.setState({ selectedDevice: device, loading: true }, () => {
        storeSelectedDevice(device.deviceId);
        const client = this.createClient();
        this.setState({ mqttClient: client });
      });
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12" className="text-center">
              {
                <h1>
                  {this.state.selectedDevice && this.state.selectedDevice.name}
                </h1>
              }
              {this.state.devices.length > 1 && (
                <PumpSelector
                  selectedDevice={this.state.selectedDevice || {}}
                  devices={this.state.devices}
                  onSelectDevice={this.onUpdateDevice}
                  loading={this.state.devicesLoading}
                />
              )}
            </Col>
          </Row>
          {!this.state.loadingAlarms && 
          <Row>
            <Col md="12">
              <Card className="card-plain">
                <CardHeader>Configuracion de Alarmas</CardHeader>
                <CardBody className="mt-2">
                  <Row className="justify-content-center text-center">
                    <Col xs="12" md="6">
                      <h3>Alarma 1</h3>
                      <AlarmForm
                        onSubmit={data => this.onSearchAlarm(data, "1")}
                        configs={this.state.alarmData1}
                      />
                    </Col>
                    <Col xs="12" md="6">
                      <h3>Alarma 2</h3>
                      <AlarmForm
                        onSubmit={data => this.onSearchAlarm(data, "2")}
                        configs={this.state.alarmData2}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
        </div>
      </>
    );
  }
}

export default Configuration;
