import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Spinner
} from "reactstrap";
import ParamChart from "./ParamChart";


class ChartList extends React.Component {


  LoadingCharts() {
    return (
      <div className="d-flex align-items-center flex-column big-paragraph mb-5">
        <Spinner type="grow" color="light" />
        <br />
        <p>Procesando...</p>
      </div>
    );
  }

  EmptyCharts() {
    return (
      <div className="font-icon-detail big-paragraph">
        <i className="agt-icons icon-chart-bar-32" />
        <p>No hay datos</p>
      </div>
    );
  }

  render() {
    return (
      <div className="content">
        {this.props.loading && this.LoadingCharts()}
        {/* {!this.props.charts.length && !this.props.loading && this.EmptyCharts()} */}
        {this.props.charts.map(({ dataGroup, ...chart }, i) => {
          if (chart.labels.length <= 0) {
            return (
              this.EmptyCharts()
            )
          }
          return (
            <Row key={i}>
              <Col xs="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <CardTitle tag="h2">{dataGroup.name}</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <ParamChart dataGroup={dataGroup} chartData={chart}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )
        })}
        
      </div>
    );
  }
}

export default ChartList;