import React from "react";
import { Col, Button, Spinner } from "reactstrap";

// Images
import step1Loading from "assets/img/botones-15.png";
import step2Loading from "assets/img/botones-16.png";
import step3Loading from "assets/img/botones-17.png";
import step1Off from "assets/img/botones-03.png";
import step2Off from "assets/img/botones-04.png";
import step3Off from "assets/img/botones-05.png";
import step1On from "assets/img/botones-09.png";
import step2On from "assets/img/botones-10.png";
import step3On from "assets/img/botones-11.png";

function renderButtonImage(btn, index) {
  let src;
  if (!btn) {
    switch (index) {
      case 1:
        src = step1Loading;
        break;
      case 2:
        src = step2Loading;
        break;
      case 3:
        src = step3Loading;
        break;
    }
  } else {
    switch (index) {
      case 1:
        if (btn === "on") src = step1On;
        if (btn === "off") src = step1Off;
        break;
      case 2:
        if (btn === "on") src = step2On;
        if (btn === "off") src = step2Off;
        break;
      case 3:
        if (btn === "on") src = step3On;
        if (btn === "off") src = step3Off;
        break;
    }
  }

  return <img width="120" src={src} alt="Pump button" />;
}

export default function PumpButtons(props) {
  const { buttonsState, onClick } = props;

  return buttonsState.map((btn, i) => (
    <Col xs="4" className="text-center" key={i}>
      <Button
        className="pump-buttons"
        block
        onClick={() => onClick(btn === "off" ? `on${i + 1}` : `off${i + 1}`)}
      >
        {renderButtonImage(btn, i + 1)}
      </Button>
    </Col>
  ));
}
