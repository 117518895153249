import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

import Money from "components/Money/Money";

// Create styles
const styles = StyleSheet.create({
  doc: {
    width: "100%",
    height: "70vh",
  },
  page: {
    backgroundColor: "white",
    paddingHorizontal: 20,
    marginTop: 15,
  },
  section: {
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  column: {
    margin: 3,
    padding: 15,
    flexGrow: 1,
    backgroundColor: "#f8f8f9",
  },
  textSm: {
    fontSize: 11,
  },
  textLg: {
    fontSize: 25,
  },
  fontBold: {
    fontStyle: "bold",
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 3,
  },
  table: {
    width: "100%",
  },
  tableHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#27886F",
    color: "#fff",
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    padding: 15,
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingHorizontal: 15,
    paddingVertical: 3,
  },
  bgCream: {
    backgroundColor: "#f8f8f9",
  },
});

const CFE = ({ data }) => {
  const {
    contractName,
    serviceNum,
    rmu,
    periodStart,
    periodEnd,
    cfeContractType,
    meterNum,
    multiplier,
    connectedLoadKw,
    contractedDemandKw,
    totalPayment,
    address,
    powerFactor,
    rates,
    charges,
  } = data;
  const [street, house, suburb, city, postalCode] = address.split("\r\n");
  // Map of the needed charges
  const usedCharges = [5, 6, 7, 8, 14, 9, 10];
  const usedTotals = [0, 3, 4, 13, 12];
  return (
    <Document style={styles.doc}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.column}>
            <Text style={{ marginBottom: 5 }}>{contractName}</Text>
            <Text style={styles.textSm}>{street}</Text>
            <Text style={styles.textSm}>{house}</Text>
            <Text style={styles.textSm}>{suburb}</Text>
            <Text style={styles.textSm}>{city}</Text>
            <Text style={styles.textSm}>{postalCode}</Text>
          </View>
          <View style={styles.column}>
            <Text style={{ ...styles.textSm, ...styles.fontBold }}>
              TOTAL A PAGAR
            </Text>
            <Text style={{ ...styles.textLg, marginTop: 5 }}>
              <Money value={totalPayment} />
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.column}>
            <View style={styles.inline}>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                NO DE SERVICIO:
              </Text>
              <Text style={{ ...styles.textSm, marginLeft: 3 }}>
                {serviceNum}
              </Text>
            </View>
            <View style={styles.inline}>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>RMU:</Text>
              <Text style={{ ...styles.textSm, marginLeft: 3 }}>{rmu}</Text>
            </View>
          </View>
          <View style={styles.column}>
            <View>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                PERIODO FACTURADO:
              </Text>
              <Text style={{ ...styles.textSm, marginTop: 3 }}>
                {new Date(periodStart).toLocaleDateString("es-MX", {
                  dateStyle: "medium",
                })}{" "}
                -{" "}
                {new Date(periodEnd).toLocaleDateString("es-MX", {
                  dateStyle: "medium",
                })}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.column}>
            <View>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                TARIFA:
              </Text>
              <Text style={{ ...styles.textSm, marginTop: 3 }}>
                {cfeContractType}
              </Text>
            </View>
          </View>
          <View style={styles.column}>
            <View>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                NO MEDIDOR:
              </Text>
              <Text style={{ ...styles.textSm, marginTop: 3 }}>{meterNum}</Text>
            </View>
          </View>
          <View style={styles.column}>
            <View>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                MULTIPLICADOR:
              </Text>
              <Text style={{ ...styles.textSm, marginTop: 3 }}>
                {multiplier}
              </Text>
            </View>
          </View>
          <View style={styles.column}>
            <View>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                FECHA LIMITE DE PAGO:
              </Text>
              <Text style={{ ...styles.textSm, marginTop: 3 }}>NULL</Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.column}>
            <View>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                CARGA CONTECTADA kW:
              </Text>
              <Text style={{ ...styles.textSm, marginTop: 3 }}>
                {connectedLoadKw}
              </Text>
            </View>
          </View>
          <View style={styles.column}>
            <View>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                DEMANDA CONTRATADA kW:
              </Text>
              <Text style={{ ...styles.textSm, marginTop: 3 }}>
                {contractedDemandKw}
              </Text>
            </View>
          </View>
          <View style={styles.column}>
            <View>
              <Text style={{ ...styles.textSm, ...styles.fontBold }}>
                CORTE A PARTIR:
              </Text>
              <Text style={{ ...styles.textSm, marginTop: 3 }}>NULL</Text>
            </View>
          </View>
        </View>

        <View style={{ ...styles.section, marginTop: 20 }}>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={{ width: "20%" }}>Concepto</Text>
              <Text style={{ width: "20%" }}>No. Medidor</Text>
              <Text style={{ width: "20%" }}>Precio</Text>
              <Text style={{ width: "20%", fontSize: 15 }}>Consumo (kW)</Text>
              <Text style={{ width: "20%", textAlign: "left" }}>Totales</Text>
            </View>
            <View>
              {rates.map((r, i) => (
                <View
                  key={i}
                  style={{
                    ...styles.tableRow,
                    ...(i % 2 !== 0 ? styles.bgCream : {}),
                  }}
                >
                  <Text style={{ width: "20%", fontSize: 15 }}>{r.rate}</Text>
                  <Text style={{ width: "20%", fontSize: 15 }}>{meterNum}</Text>
                  <Text style={{ width: "20%", fontSize: 15 }}>
                    <Money value={r.price} />
                  </Text>
                  <Text style={{ width: "20%", fontSize: 15 }}>
                    {r.reads[0].value}
                  </Text>
                  <Text style={{ width: "20%", fontSize: 15 }}>
                    <Money value={r.totalPay} />
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        <View style={{ ...styles.section, marginTop: 10 }}>
          <View style={styles.table}>
            <View style={{ ...styles.tableHeading, textAlign: "right" }}>
              <Text style={{ fontSize: 15 }}>Factor Potencia</Text>
            </View>
            <View style={{ textAlign: "right" }}>
              <View
                style={{ ...styles.tableRow, ...styles.bgCream, fontSize: 15 }}
              >
                <Text>{powerFactor.toFixed(2)}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ ...styles.section, marginTop: 10 }}>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={{ width: "50%" }}>Concepto</Text>
              <Text style={{ width: "50%" }}>Importe (MXN)</Text>
            </View>
            <View>
              {usedCharges.map((cidx, i) => (
                <View
                  key={i}
                  style={{
                    ...styles.tableRow,
                    ...(i % 2 !== 0 ? styles.bgCream : {}),
                  }}
                >
                  <Text style={{ width: "50%", fontSize: 15 }}>
                    {charges[cidx].description}
                  </Text>
                  <Text style={{ width: "50%", fontSize: 15 }}>
                    <Money value={charges[cidx].value} />
                  </Text>
                </View>
              ))}
              <View style={styles.tableRow}>
                <Text style={{ width: "50%", fontSize: 15 }}>Total</Text>
                <Text style={{ width: "50%", fontSize: 15 }}>
                  <Money
                    value={usedCharges.reduce(
                      (acc, cv) => acc + charges[cv].value,
                      0
                    )}
                  />
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ ...styles.section, marginTop: 40 }}>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={{ width: "50%" }}>Concepto</Text>
              <Text style={{ width: "50%" }}>Importe (MXN)</Text>
            </View>
            <View>
              {usedTotals.map((cidx, i) => (
                <View
                  key={i}
                  style={{
                    ...styles.tableRow,
                    ...(i % 2 !== 0 ? styles.bgCream : {}),
                  }}
                >
                  <Text style={{ width: "50%", fontSize: 15 }}>
                    {charges[cidx].description}
                  </Text>
                  <Text style={{ width: "50%", fontSize: 15 }}>
                    <Money value={charges[cidx].value} />
                  </Text>
                </View>
              ))}
              <View style={styles.tableRow}>
                <Text style={{ width: "50%", fontSize: 15 }}>Total</Text>
                <Text style={{ width: "50%", fontSize: 15 }}>
                  <Money value={totalPayment} />
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default (report) => {
  return (
    <PDFViewer style={{ width: "100%", height: "80vh" }}>
      <CFE data={report} />
    </PDFViewer>
  );
};
