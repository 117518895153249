import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
} from "reactstrap";

import API from "../api";
import CFEReport from "components/Reports/CFE";

export default function Reports() {
  const [reportDate, setReportDate] = useState();
  const [report, setReport] = useState();
  const [contracts, setContracts] = useState();
  const [selectedContract, setSelectedContract] = useState();

  useEffect(() => {
    async function fetchReportContracts() {
      const contracts = await API.getCFEContracts();
      setContracts(contracts);
    }

    fetchReportContracts();
  }, []);

  useEffect(() => {
    async function fetchReportData() {
      const data = await API.getReportData({
        id: selectedContract,
        date: reportDate,
      });
      setReport(data);
    }

    if (!reportDate || !selectedContract) return;

    fetchReportData();
  }, [reportDate, selectedContract]);

  return (
    <div className="content">
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <h1>Reportes</h1>
              <Row>
                <Col xs={12} sm={6}>
                  <Form>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label className={"filter-label"}>Contrato:</Label>
                          <Input
                            type="select"
                            onChange={(e) => {
                              setSelectedContract(e.target.value);
                            }}
                          >
                            <option value="selected">
                              Selecciona un contrato
                            </option>
                            {contracts?.map((c) => (
                              <option
                                key={c.cfeContractId}
                                value={c.cfeContractId}
                              >
                                {c.contractName}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label className={"filter-label"}>Fecha:</Label>
                          <Input
                            type="date"
                            onChange={(e) => {
                              setReportDate(
                                new Date(e.target.value).toISOString()
                              );
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="justify-content-center m-t-3">
              {report && <CFEReport {...report} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
